import * as React from "react"
import { Helmet } from "react-helmet";

import "../styles/main.scss";
import { Navigation, Content, Banner } from "../components";
import swarm from "../images/swarm.png";

import flag from "../images/icons/flag.svg";
import people from "../images/icons/people.svg";
import map from "../images/icons/map.svg";

// --------------------------------------------------------------------------------
// Banner
// --------------------------------------------------------------------------------
function PageBanner(): JSX.Element {
    const title = <>Plan, monitor and track workforce changes</>;

    const subtitle = <>
        <p>
            Employee analytics for mergers and acquisitions.<br />
            Cost reduction programme reporting.<br />
            Support headcount reduction with accurate information.
        </p>
    </>;

    // const sparkle = <img className="w-100 h-100 border ps-2 py-2 bg-white" src={swarm} width="417px" height="288px" alt="" aria-label="" />;
    const sparkle = <div className="text-md-end">
        <img src={swarm} alt="Sample swarm plot" className="bg-white border mw-100 xmh-100" />
    </div>;

    return <Banner
        title={title}
        subtitle={subtitle}
        action={{ to: "/contact-us/", caption: "Request a demo" }}
        sparkle={sparkle}
    />;
}



// --------------------------------------------------------------------------------
// Introduction
// --------------------------------------------------------------------------------
function PageIntro(): JSX.Element {
    const intro = <>
        <h2>Supporting your transformation programme</h2>
        <p>
            We provide workforce data management, analytics and consultancy services to help you design,
            implement and track headcount reduction programmes, mergers and organisation redesign.
        </p>
        <p>
            Provide dashboards, regular reports and metrics to stakeholders through the
            critical stages of your change programmes.
        </p>
    </>;

    return <div className="row row-cols-1 row-cols-sm-1 pb-4 gy-4">
        <div className="col text-sm-center px-4 ">
            {intro}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Body
// --------------------------------------------------------------------------------
function PageBody(): JSX.Element {
    const body1 = <>
        <h2>Plan organisation transformation</h2>
        <p>
            Analyse your as-is state and compare the impact of different implementation strategies.
            Combine HR, Finance, Sales and other business datasets.
        </p>
        <p>
            Compare costs, savings and benefits to understand the business impact.
            Highlight risks and impact of different options and decisions.
        </p>
    </>;

    const body2 = <>
        <h2>Track the implementation</h2>
        <p>
            Regular reports provide up to date information to programme leaders and senior stakeholders.
            Create monthly, weekly and ad&nbsp;hoc reports to monitor implementation progress.
        </p>
    </>

    return <div className="row row-cols-1 row-cols-sm-2 pb-4 gy-4">
        <div className="col px-4">
            {body1}
        </div>
        <div className="col px-4">
            {body2}
        </div>
    </div>;
}


// --------------------------------------------------------------------------------
// Features
// --------------------------------------------------------------------------------
function PageFeatures(): JSX.Element {
    const block1 = <>
        <div className="px-0 py-4">
            <img src={people} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Mergers and acquisitions</h2>
            <ul>
                <li>Identify required headcount reduction targets and costs</li>
                <li>Compare workforce skills and demographics before and after a merger</li>
                <li>Benchmark salary, benefits and employment terms</li>
                <li>Ensure compliance targets for diversity and equality are met</li>
            </ul>
        </div>
    </>;

    const block2 =
        <div className="px-0 py-4">
            <img src={flag} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />
            <h2>Headcount and cost reduction</h2>
            <ul>
                <li>Report severance costs and savings</li>
                <li>Identify skills gaps resulting from restructuring</li>
                <li>Identify patterns, trends and potential issues</li>
                <li>Analyse plans and predicted outcomes</li>
                <li>Conduct scenario planning and what-ifs</li>
                <li>Provide senior stakeholders with regular change management reports</li>
            </ul>
        </div>;

    const block3 = <>
        <div className="px-0 py-4">
            <img src={map} className="my-0 mb-4" width="100%" height="40x" alt="" aria-label="Home" />

            <h2>Re-organisation and job redesign</h2>
            <ul>
                <li>Analyse existing skills and identify gaps</li>
                <li>Review and optimise spans and layers of control</li>
                <li>Analyse capability and capacity</li>
                <li>Explore options and plan for change</li>
            </ul>
        </div>
    </>;

    return <div className="row row-cols-1 row-cols-sm-3">
        <div className="col qbl-block-1 px-4">
            {block1}
        </div>
        <div className="col qbl-block-2 px-4">
            {block2}
        </div>
        <div className="col qbl-block-1 px-4">
            {block3}
        </div>
    </div>;
}


//

const PeopleAnalytics = () =>
    <>
        <Helmet title="Qebal Transformation Analytics" htmlAttributes={{ lang: 'en' }} />
        <Navigation />
        <Content>
            <div className="container-lg">
                <PageBanner />
                <PageIntro />
                <PageBody />
                <PageFeatures />
            </div>
        </Content>
    </>;

export default PeopleAnalytics;